import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _runAt2 from "./runAt";
import _glyphIndexAt2 from "../run/glyphIndexAt";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _runAt = _interopRequireDefault(_runAt2);

var _glyphIndexAt = _interopRequireDefault(_glyphIndexAt2);
/**
 * Get glyph width at string index
 *
 * @param {number} string index
 * @param {Object} attributed string
 * @return {number} glyph width
 */


var glyphWidthAt = function glyphWidthAt(index, string) {
  var run = (0, _runAt.default)(index, string);
  var glyphIndex = (0, _glyphIndexAt.default)(index, run);
  return run.positions[glyphIndex].xAdvance;
};

var _default = glyphWidthAt;
exports.default = _default;
export default exports;