import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _length2 from "../run/length";
import _advanceWidth2 from "../run/advanceWidth";
import _indexAtOffset2 from "../run/indexAtOffset";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _length = _interopRequireDefault(_length2);

var _advanceWidth = _interopRequireDefault(_advanceWidth2);

var _indexAtOffset = _interopRequireDefault(_indexAtOffset2);
/**
 * Get string index at offset
 *
 * @param  {Object}  attributed string
 * @param  {number}  offset
 * @return {number} string index at offset N
 */


var indexAtOffset = function indexAtOffset(offset, string) {
  var index = 0;
  var counter = 0;
  var runs = R.propOr([], "runs", string);

  for (var i = 0; i < runs.length; i += 1) {
    var run = runs[i];
    var advanceWidth = (0, _advanceWidth.default)(run);

    if (counter + advanceWidth > offset) {
      return index + (0, _indexAtOffset.default)(offset - counter, run);
    }

    counter += advanceWidth;
    index += (0, _length.default)(run);
  }

  return index;
};

var _default = R.curryN(2, indexAtOffset);

exports.default = _default;
export default exports;