import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _slice2 from "./slice";
import _indexAtOffset2 from "./indexAtOffset";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _slice = _interopRequireDefault(_slice2);

var _indexAtOffset = _interopRequireDefault(_indexAtOffset2);
/**
 * Slice attributed string at given offset
 *
 * @param  {number}  offset
 * @param  {Object}  attributedString
 * @return {Object} attributedString
 */


var sliceAtOffset = function sliceAtOffset(offset, string) {
  var index = (0, _indexAtOffset.default)(offset, string);
  return (0, _slice.default)(0, index, string);
};

var _default = R.curryN(2, sliceAtOffset);

exports.default = _default;
export default exports;