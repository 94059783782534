import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _runIndexAt2 from "./runIndexAt";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _runIndexAt = _interopRequireDefault(_runIndexAt2);
/**
 * Get run at char index
 *
 * @param  {number}  char index
 * @param  {Object}  attributedString
 * @return {Object} run
 */


var runAt = function runAt(n, attributedString) {
  var runIndex = (0, _runIndexAt.default)(n)(attributedString);
  return R.path(["runs", runIndex])(attributedString);
};

var _default = R.curryN(2, runAt);

exports.default = _default;
export default exports;