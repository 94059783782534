import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _add2 from "../run/add";
import _empty2 from "../run/empty";
import _prepend2 from "../run/prepend";
import _stringFromCodePoints2 from "../utils/stringFromCodePoints";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _add = _interopRequireDefault(_add2);

var _empty = _interopRequireDefault(_empty2);

var _prepend = _interopRequireDefault(_prepend2);

var _stringFromCodePoints = _interopRequireDefault(_stringFromCodePoints2);
/**
 * prepend glyph into last run of attributed string
 *
 * @param {Object} glyph
 * @param {Object} attributed string
 * @return {Object} attributed string with new glyph
 */


var prepend = function prepend(glyph, string) {
  var codePoints = R.propOr([], "codePoints")(glyph);
  return R.evolve({
    string: R.concat((0, _stringFromCodePoints.default)(codePoints)),
    runs: R.converge(R.concat, [R.compose(R.unapply(R.identity), (0, _prepend.default)(glyph), R.either(R.head, _empty.default)), R.compose(R.map((0, _add.default)(codePoints.length)), R.tail)])
  })(string);
};

var _default = R.curryN(2, prepend);

exports.default = _default;
export default exports;