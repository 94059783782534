import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _copy2 from "./copy";
import _scale2 from "./scale";
import _getFont2 from "./getFont";
import _isNumber2 from "../utils/isNumber";
import _prepend2 from "../indices/prepend";
import _fromCodePoint2 from "../glyph/fromCodePoint";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _copy = _interopRequireDefault(_copy2);

var _scale = _interopRequireDefault(_scale2);

var _getFont = _interopRequireDefault(_getFont2);

var _isNumber = _interopRequireDefault(_isNumber2);

var _prepend = _interopRequireDefault(_prepend2);

var _fromCodePoint = _interopRequireDefault(_fromCodePoint2);
/**
 * Prepend glyph to run
 *
 * @param  {Object}  glyph
 * @param  {Object}  run
 * @return {Object} run with glyph
 */


var prependGlyph = function prependGlyph(glyph, run) {
  var runScale = (0, _scale.default)(run);
  var glyphLength = R.length(glyph.codePoints);
  return R.evolve({
    end: R.add(glyphLength),
    glyphIndices: (0, _prepend.default)(glyphLength),
    glyphs: R.prepend(glyph),
    positions: R.prepend({
      xAdvance: glyph.advanceWidth * runScale
    })
  })(run);
};
/**
 * Prepend glyph or code point on run
 *
 * @param  {Object | number}  glyph | codePoint
 * @param  {Object}  run
 * @return {Object} run with glyph
 */


var prepend = function prepend(value, run) {
  if (!value) return (0, _copy.default)(run);
  var font = (0, _getFont.default)(run);
  var glyph = (0, _isNumber.default)(value) ? (0, _fromCodePoint.default)(value, font) : value;
  return prependGlyph(glyph, run);
};

var _default = R.curryN(2, prepend);

exports.default = _default;
export default exports;