import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _advanceWidth2 from "./advanceWidth";
import _advanceWidthBetween2 from "./advanceWidthBetween";
import _append2 from "./append";
import _ascent2 from "./ascent";
import _copy2 from "./copy";
import _descent2 from "./descent";
import _dropLast2 from "./dropLast";
import _empty2 from "./empty";
import _end2 from "./end";
import _fromFragments2 from "./fromFragments";
import _glyphWidthAt2 from "./glyphWidthAt";
import _height2 from "./height";
import _indexAtOffset2 from "./indexAtOffset";
import _insertGlyph2 from "./insertGlyph";
import _leadingOffset2 from "./leadingOffset";
import _length2 from "./length";
import _prepend2 from "./prepend";
import _reduce2 from "./reduce";
import _runAt2 from "./runAt";
import _runIndexAt2 from "./runIndexAt";
import _slice2 from "./slice";
import _sliceAtOffset2 from "./sliceAtOffset";
import _start2 from "./start";
import _trailingOffset2 from "./trailingOffset";
import _trim2 from "./trim";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _advanceWidth = _interopRequireDefault(_advanceWidth2);

var _advanceWidthBetween = _interopRequireDefault(_advanceWidthBetween2);

var _append = _interopRequireDefault(_append2);

var _ascent = _interopRequireDefault(_ascent2);

var _copy = _interopRequireDefault(_copy2);

var _descent = _interopRequireDefault(_descent2);

var _dropLast = _interopRequireDefault(_dropLast2);

var _empty = _interopRequireDefault(_empty2);

var _end = _interopRequireDefault(_end2);

var _fromFragments = _interopRequireDefault(_fromFragments2);

var _glyphWidthAt = _interopRequireDefault(_glyphWidthAt2);

var _height = _interopRequireDefault(_height2);

var _indexAtOffset = _interopRequireDefault(_indexAtOffset2);

var _insertGlyph = _interopRequireDefault(_insertGlyph2);

var _leadingOffset = _interopRequireDefault(_leadingOffset2);

var _length = _interopRequireDefault(_length2);

var _prepend = _interopRequireDefault(_prepend2);

var _reduce = _interopRequireDefault(_reduce2);

var _runAt = _interopRequireDefault(_runAt2);

var _runIndexAt = _interopRequireDefault(_runIndexAt2);

var _slice = _interopRequireDefault(_slice2);

var _sliceAtOffset = _interopRequireDefault(_sliceAtOffset2);

var _start = _interopRequireDefault(_start2);

var _trailingOffset = _interopRequireDefault(_trailingOffset2);

var _trim = _interopRequireDefault(_trim2);

var _default = {
  advanceWidth: _advanceWidth.default,
  advanceWidthBetween: _advanceWidthBetween.default,
  append: _append.default,
  ascent: _ascent.default,
  copy: _copy.default,
  descent: _descent.default,
  dropLast: _dropLast.default,
  empty: _empty.default,
  end: _end.default,
  fromFragments: _fromFragments.default,
  glyphWidthAt: _glyphWidthAt.default,
  height: _height.default,
  indexAtOffset: _indexAtOffset.default,
  insertGlyph: _insertGlyph.default,
  leadingOffset: _leadingOffset.default,
  length: _length.default,
  prepend: _prepend.default,
  reduce: _reduce.default,
  runAt: _runAt.default,
  runIndexAt: _runIndexAt.default,
  slice: _slice.default,
  sliceAtOffset: _sliceAtOffset.default,
  start: _start.default,
  trailingOffset: _trailingOffset.default,
  trim: _trim.default
};
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;