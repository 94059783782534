import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _copy2 from "../run/copy";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _copy = _interopRequireDefault(_copy2);
/**
 * Deep clone attributed string
 *
 * @param  {Object}  attributed string
 * @return {Object} cloned attributed string
 */


var copy = R.evolve({
  string: R.identity,
  syllables: R.identity,
  runs: R.map(_copy.default)
});
var _default = copy;
exports.default = _default;
export default exports;